import customerEoiService from "@services/customerEoi/customerEoiService";
import stockService from "@services/stock/stockService";
import { map } from "lodash";
import { action, observable } from "mobx";

export interface IBuyerForm {
  creatorUserId?: any,
  creationTime?: string,
  deleteUserId?: any,
  deletionTime?: string,
  middleName?: string,
  firstName?: string,
  isDeleted?: true,
  lastName?: string,
  address?: string,
  email?: string,
  phone?: string,
  state?: any,
  city?: string,
  type?: any,
  id?: any
}

export interface IUloadPrivateForm {
  creatorUserId?: any,
  creationTime?: string,
  deleteUserId?: any,
  uniqueId?: any,
  documentName?: string,
  documentTypeId?: number,
  uploadDate?: string
}

export interface IDocumentBuyerForm {
  referenceId?: any,
  fileUrl?: string,
  mimeType?: any,
  originalFileName?: any,
  moduleName?: any,
  fileName?: any,
  documentName?: any,
  documentTypeId?: any,
  id?: any
}

export interface ISolicitorForm {
  creatorUserId?: any,
  creationTime?: string,
  firmName?: any,
  solicitorName?: string,
  email?: string,
  phone?: string,
  address?: true,
  city?: string,
  state?: string,
  purchasingType?: string,
  registeredCustomerByAgentId?: string,
  id?: any
}

export interface IUloadPaymentTranferForm {
  creatorUserId?: any,
  creationTime?: string,
  deleteUserId?: any,
  uniqueId?: any,
  documentName?: string,
  documentTypeId?: number,
  uploadDate?: string
}

interface IVerifyEOIBuyer {
  buyerId: string,
  isVerify: boolean,
}

interface IVerifyEOISolicitor {
  SolicitorId: string,
  isVerify: boolean,
}

interface IPaymentDocument {
  registeredCustomerByAgentId: number,
  documentTypeId: number
}

interface IVerifyEOIPayment {
  registeredCustomerByAgentId: number,
  isVerify: boolean,
}

interface IVerifyEOISignature {
  referenceId: string,
  registeredCustomerByAgentId: number,
  isVerify: boolean,
}
class CustomerEOIStore {
  @observable isLoading: boolean = false;
  // 0 [None]  // 1 [Present]  // 2 [Error]  // 3 [Done]
  @observable registeredCustomerByAgentId: number = 0;
  @observable stockListId: any = 0;
  @observable progressData: any[] = [{
    name: 'Buyer details',
    type: 'detail',
    status: 1, // 3,
    isShow: true
  }, {
    name: 'Upload documents',
    type: 'upload',
    status: 0,
    isShow: false
  }, {
    name: 'Solicitor details',
    type: 'solicitor',
    status: 0,
    isShow: false
  }, {
    name: 'Payment',
    type: 'payment',
    status: 0,
    isShow: false
  }, {
    name: 'Review & sign',
    type: 'review',
    status: 0,
    isShow: false
  }];

  @observable lastedUpdateEoi: Date = new Date();
  @observable dataStockListDetail: any = {};
  @observable step: number = 1;
  @observable dataBuyer: any = [];
  @observable dataDocumentBuyer: any = [];
  @observable dataSolicitor: any = {};
  @observable dataPaymentInfo: any = {};
  @observable dataDocumentPayment: any = {};
  @observable dataPaymentProject: any = {};
  @observable dataEoiTemplate: any = {};
  @observable dataEoiSignature: any = {};

  @action
  async callGetDataStockListDetail(id?: any) {
    try {
      const { data } = await stockService.getV2(id)
      this.dataStockListDetail = data?.result;
    } catch (e) {
      console.log('=== e-data-stock-list-detail ==>', e);
    }
  }
  @action
  async callDeleteDocumentEOIByGuid(id?: any, type?: any) {
    try {
      const { data } = await customerEoiService.apiDeleteDocumentEOIByGuid(id, type);
      return data;
    } catch (e) {
      console.log('=== e-get-document-buyer-by ==>', e);
    }
  }
  //#region step1
  @action
  async callGetEOIBuyerByRegisteredCustomerByAgentId(id: number) {
    try {
      const { data } = await customerEoiService.apiGetBuyerByRegisteredCustomerByAgentId(id);
      if (data.success) {
        await this.setCurrentProcessBar(0);
        this.dataBuyer = map(data.result, (con, conI) => {
          return {
            ...(con as object),
            type: con.type == 2,
          }
        })
      }
      return data;
    } catch (e) {
      console.log('=== e-get-buyer-by ==> ', e);
    }
  }
  @action
  async callCreateUpdateEOIBuyer(params: IBuyerForm) {
    try {
      const { data } = await customerEoiService.apiCreateUpdateEOIBuyer(params)
      return data;
    } catch (e) {
      console.log('=== e-created-update-buyer ==> ', e);
    }
  }
  @action
  async callVerifyEOIBuyer(params: IVerifyEOIBuyer) {
    try {
      const { data } = await customerEoiService.apiVerifyEOIBuyer(params)
      return data;
    } catch (e) {
      console.log('=== e-verify-eoi ===>', e);
    }
  }
  @action
  async callDeletedEOIBuyer(id: string) {
    try {
      const { data } = await customerEoiService.apiDeletedEOIBuyer(id)
      return data;
    } catch (e) {
      console.log('=== e-update-buyer ==> ', e);
    }
  }
  @action
  async callDeletedEOIDirector(id: string) {
    try {
      const { data } = await customerEoiService.apiDeletedEOIDirector(id)
      return data;
    } catch (e) {
      console.log('=== e-update-buyer ==> ', e);
    }
  }
  //#endregion
  //#region step2
  @action
  async getDocumentEOIBuyerByRegisteredCustomerByAgentId(id?: any) {
    try {
      const { data } = await customerEoiService.apiGetDocumentEOIBuyerByRegisteredCustomerByAgentId(id)
      if (data && data?.success) this.dataDocumentBuyer = data.result;
      return data;
    } catch (e) {
      console.log('=== e-get-document-buyer-by ==>', e);
    }
  }
  @action
  async callCreateOrUpdateDocumentEOIBuyer({ param, config }) {
    try {
      const { data } = await customerEoiService.apiCreateOrUpdateDocumentForBuyer(param);
      return data;
    } catch (e) {
      console.log('=== e-post-creat-or-update-document-buyer-by ==> ', e);
    }
  }
  //#endregion
  //#region step3 => Solicitor
  @action
  async callGetEOISolicitorByRegisteredCustomerByAgentId(id?: any) {
    try {
      const { data } = await customerEoiService.apiGetEOICustomerSolicitor(id);
      if (data && data.success) this.dataSolicitor = data.result[0];

      return data;
    } catch (e) {
      console.log('=== e-get-document-buyer-by ==>', e);
    }
  }

  @action
  async callCreateOrUpdateEOISolicitor(params: ISolicitorForm) {
    this.isLoading = true;
    try {
      const { data } = await customerEoiService.apiCreateOrUpdateEOISolicitor(params)
      // this.isLoading = false;
      return data;
    } catch (e) {
      // this.isLoading = false;
      console.log('=== e-create-or-update-buyer-company ==>', e);
    }
  }
  @action
  async callVerifyEOISolicitor(params: IVerifyEOISolicitor) {
    try {
      const { data } = await customerEoiService.apiVerifyEOISolicitor(params)
      return data;
    } catch (e) {
      console.log('=== e-verify-eoi ===>', e);
    }
  }

  //#endregion
  //#region step4 => payment
  @action
  async callGetPaymentProjectSettingByProjectId(id?: any, registeredCustomerByAgentId?: any) {
    try {
      registeredCustomerByAgentId > 0 ? registeredCustomerByAgentId : this.registeredCustomerByAgentId;
      const { data } = await customerEoiService.apiGetPaymentProjectSettingByProjectId(id, registeredCustomerByAgentId);
      if (data && data.success) this.dataPaymentProject = data.result;

      return data;
    } catch (e) {
      console.log('=== e-get-document-buyer-by ==>', e);
    }
  }
  @action
  async callGetEOIPaymentByRegisteredCustomerByAgentId(id?: any) {
    try {
      const { data } = await customerEoiService.apiGetEOIPaymentByRegisteredCustomerByAgentId(id);
      if (data && data?.success) this.dataPaymentInfo = data.result;
      return data;
    } catch (e) {
      console.log('=== e-get-document-buyer-by ==>', e);
    }
  }
  @action
  async callCreateOrUpdateDocumentPaymentTranfer({ param, config }) {
    try {
      const { data } = await customerEoiService.apiCreateOrUpdateEOIPaymentTranfer(param)
      return data;
    } catch (e) {
      console.log('=== e-create-update-payment ==>', e);
    }
  }
  @action
  async callVerifyEOIPayment(params: IVerifyEOIPayment) {
    try {
      const res= await customerEoiService.apiVerifyEOIPayment(params)
      return res;
    } catch (e) {
      console.log('=== e-verify-eoi ===>', e);
    }
  }
  @action
  async callGetDocumentForEOIPayment(params: IPaymentDocument) {
    try {
      const { data } = await customerEoiService.apiGetDocumentForEOIPayment(params)
      if (data && data?.success) this.dataDocumentPayment = data.result;
      return data;
    } catch (e) {
      console.log('=== e-get-document-payment-by ==>', e);
    }
  }
  @action
  async callCreatedStripeIntent(params: any) {
    try {
      const { data } = await customerEoiService.apiCreatedStripeIntent(params)
      return data;
    } catch (e) {
      console.log('=== e-created-stripe-tanet ==>', e);
    }
  }

  @action
  async callCreatedTrackingPayentOnline(params: any) {
    try {
      const { data } = await customerEoiService.apiCreatedTrackingPayentOnline(params)
      return data;
    } catch (e) {
      console.log('=== e-created-payment-online-tanet ==>', e);
    }
  }
  @action
  async previewEOITemplate(params?: any) {
    this.isLoading = true;
    try {
      const { data } = await customerEoiService.previewEOITemplate({ ...params })
      if (data && data?.success) this.dataEoiTemplate = data.result;
      return data;
    } catch (e) {
      console.log('=== e-previewEOITemplate ==>', e);
    }
    this.isLoading = false;
  }

  @action
  async downloadEOITemplate(params?: any) {
    this.isLoading = true;
    try {
      await customerEoiService.downloadEOITemplate({ ...params })
    } catch (e) {
      console.log('=== e-downloadEOITemplate ==>', e);
    }
    this.isLoading = false;
  }
  //#endregion

  //step5
  @action
  async callGetEOISignatureByRegisteredCustomerByAgentId(registeredCustomerByAgentId?: any) {
    try {
      const { data } = await customerEoiService.apiGetEOISignatureByRegisteredCustomerByAgentId(registeredCustomerByAgentId);
      if (data && data.success) this.dataEoiSignature = data.result;

      return data;
    } catch (e) {
      console.log('=== e-get-signature-buyer-by ==>', e);
    }
  }
  @action
  async callCreateOrUpdateEOISignature(param) {
    try {
      const { data } = await customerEoiService.apiCreateOrUpdateEOISignature(param);
      return data;
    } catch (e) {
      console.log('=== e-post-creat-or-update-buyer-signature ==> ', e);
    }
  }
  @action
  async callCreateOrUpdateSignatureForBuyer(param) {
    try {
      const { data } = await customerEoiService.apiCreateOrUpdateSignatureForBuyer(param);
      return data;
    } catch (e) {
      console.log('=== e-post-creat-or-update-buyer-signature ==> ', e);
    }
  }
  @action
  async callDeletedEOISignature(id: string) {
    try {
      const { data } = await customerEoiService.apiDeletedEOISignature(id)
      return data;
    } catch (e) {
      console.log('=== e-delete-EOI-Signature ==> ', e);
    }
  }
  @action
  async callVerifyEOISignature(params: IVerifyEOISignature) {
    try {
      const { data } = await customerEoiService.apiVerifyEOISignature(params)
      return data;
    } catch (e) {
      console.log('=== e-verify-eoi ===>', e);
    }
  }
  //end step5
  // utils 
  @action
  setProgressData(data: any) {
    this.progressData = data;
  }
  @action
  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
  @action
  setSolicitor(data: any) {
    this.dataSolicitor = data
  }

  @action
  setPaymentProject(data: any) {
    this.dataPaymentProject = data
  }

  @action
  setEoiSignature(data: any) {
    this.dataEoiSignature = data
  }

  @action
  setStep(step: number) {
    this.step = step
  }

  @action
  setCustomerByAgentId(id: number) {
    this.registeredCustomerByAgentId = id
  }

  @action
  setStockListId(id: any) {
    this.stockListId = id
  }

  @action
  async setCurrentProcessBar(step: number) {
    try {
      const { data } = await customerEoiService.apiGetEOICustomerProcessBarByRegisteredCustomerByAgentId(this.registeredCustomerByAgentId)
      if (data && data?.success && data?.result) {
        this.lastedUpdateEoi = data?.result?.lastedUpdated;
        if (data?.result?.steps) {
          data?.result?.steps?.map((item) => {
            switch (item.stepName) {
              case "Step 1": this.progressData[0].status = item.status;
              case "Step 2": this.progressData[1].status = item.status;
              case "Step 3": this.progressData[2].status = item.status;
              case "Step 4": this.progressData[3].status = item.status;
              case "Step 5": this.progressData[4].status = item.status;
              default:
            }
          });
        }
      }
      this.progressData.map((item, index) => {
        if (index === step) {
          item.isShow = true;
        } else {
          item.isShow = false;
        }
      });
    } catch (e) {
      console.log('=== e-get-eoi-customer-process-bar-by ==>', e);
    }
  }
}

export default CustomerEOIStore;
