import http from "../httpService";
import { downloadFile } from "@lib/helperFile"

class CustomerEOIService {
  //general
  public async apiDeleteDocumentEOIByGuid(id: any, type: any): Promise<any> {
    const res = await http.delete(`api/services/app/EOICustomerService/DeleteDocumentEOIByGuid?guid=${id}&eOIDocumentDelete=${type}`);
    return res || {};
  }
  //Start Process Bar
  public async apiGetEOICustomerProcessBarByRegisteredCustomerByAgentId(id: number): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerService/GetProcessStepForEOICustomerByEOIId?registeredCustomerByAgentId=${id}`);
    return res || {};
  }

  public async previewEOITemplate(params: any): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerService/PreviewEOITemplate?registeredCustomerByAgentId=${params?.userId}`, params);
    return res || {};
  }

  public async downloadEOITemplate(params: any): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerService/DownloadEOITemplate?registeredCustomerByAgentId=${params?.userId}`, {
      params,
      responseType: 'blob'
    });
    downloadFile(res.data, "EoI.pdf")
  }
  
  //End Process Bar
  //#region Start Step1
  public async apiGetBuyerByRegisteredCustomerByAgentId(id: number): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerBuyerService/GetEOIBuyerByEOIId?registeredCustomerByAgentId=${id}`);
    return res || {};
  }
  public async apiCreateUpdateEOIBuyer(params: any): Promise<any> {
    const res = await http.post(`api/services/app/EOICustomerBuyerService/CreateOrUpdateEOIBuyerAndCompany`, params);
    return res || {};
  }
  public async apiVerifyEOIBuyer(params: any): Promise<any> {
    const res = await http.post(`api/services/app/EOICustomerBuyerService/VerifyEOIBuyer`, params);
    return res || {};
  }
  public async apiDeletedEOIBuyer(id: string): Promise<any> {
    const res = await http.delete(`api/services/app/EOICustomerBuyerService/DeleteEOIBuyerById?id=${id}`);
    return res || {};
  }
  public async apiDeletedEOIDirector(id: string): Promise<any> {
    const res = await http.delete(`api/services/app/EOICustomerDirectorService/DeleteEOIDirectorById?id=${id}`);
    return res || {};
  }
  //End step1
  //Start Step2
  public async apiGetDocumentEOIBuyerByRegisteredCustomerByAgentId(id: any): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerService/GetDocumentForEOICustomerUploadDocumentByEOIId?registeredCustomerByAgentId=${id}`);
    return res || {};
  }
  public async apiCreateOrUpdateDocumentForBuyer(params: any, config?: any): Promise<any> {
    const res = await http.post(`api/Documents/UploadAttachmentForEOIBuyer`, params, config);
    return res || {};
  }
  //End step2
  //#region Start Step3 => Solicitor
  public async apiGetEOICustomerSolicitor(id: any): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerSolicitorService/GetEOISolicitorByEOIId?registeredCustomerByAgentId=${id}`);
    return res || {};
  }
  public async apiGetEOICustomerSolicitorById(id: any): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerSolicitorService/GetDetailEOISolicitorById?id=${id}`);
    return res || {};
  }
  public async apiCreateOrUpdateEOISolicitor(params: any): Promise<any> {
    const res = await http.post(`api/services/app/EOICustomerSolicitorService/CreateOrUpdateEOISolicitor`, params);
    return res || {};
  }
  public async apiVerifyEOISolicitor(params: any): Promise<any> {
    const res = await http.post(`api/services/app/EOICustomerSolicitorService/VerifyEOISolicitor`, params);
    return res || {};
  }
  //End step3

  //#region Start Step4 => payment
  public async apiGetPaymentProjectSettingByProjectId(id: any, registeredCustomerByAgentId: any): Promise<any> {
    const res = await http.get(`api/services/app/PaymentProjectSettingService/GetPaymentProjectSettingByProjectId?projectId=${id}&registeredCustomerByAgentId=${registeredCustomerByAgentId}`);
    return res || {};
  }
  public async apiGetEOIPaymentByRegisteredCustomerByAgentId(id: any): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerPaymentService/GetEOIDirectPaymentByRegisteredCustomerByAgentId?registeredCustomerByAgentId=${id}`);
    return res || {};
  }
  public async apiCreateOrUpdateEOIPaymentTranfer(params: any, config?: any): Promise<any> {
    const res = await http.post(`api/Documents/UploadAttachmentForEOIPayment`, params);
    return res || {};
  }
  public async apiVerifyEOIPayment(params: any): Promise<any> {
    const res = await http.post(`api/services/app/EOICustomerPaymentService/VerifyEOIPayment`, params);
    return res || {};
  }
  public async apiGetDocumentForEOIPayment(params: any): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerPaymentService/GetAttachmentForEOIPaymentById?registeredCustomerByAgentId=${params.registeredCustomerByAgentId}&DocumentTypeId=${params?.documentTypeId}`, params);
    return res || {};
  }
  public async apiCreatedStripeIntent(params: any): Promise<any> {
    const res = await http.post(`api/services/app/EOICustomerPaymentService/CreatedStripeIntent`, params);
    return res || {};
  }
  public async apiCreatedTrackingPayentOnline(params: any): Promise<any> {
    const res = await http.post(`api/services/app/EOICustomerPaymentService/CreatedTrackingPaymentIntent`, params);
    return res || {};
  }
  //End step4 
  //step5
  public async apiGetEOISignatureByRegisteredCustomerByAgentId(registeredCustomerByAgentId: any): Promise<any> {
    const res = await http.get(`api/services/app/EOICustomerSignatureService/GetEOISignature?registeredCustomerByAgentId=${registeredCustomerByAgentId}`);
    return res || {};
  }
  public async apiCreateOrUpdateEOISignature(params: any): Promise<any> {
    const res = await http.post(`api/services/app/EOICustomerSignatureService/CreateOrUpdateEOISignature`, params);
    return res || {};
  }
  public async apiCreateOrUpdateSignatureForBuyer(params: any): Promise<any> {
    const res = await http.post(`api/services/app/EOICustomerSignatureService/CreateOrUpdateSignatureForBuyer`, params);
    return res || {};
  }
  public async apiDeletedEOISignature(id: string): Promise<any> {
    const res = await http.delete(`api/services/app/EOICustomerSignatureService/DeleteEOISignatureById?id=${id}`);
    return res || {};
  }
  public async apiVerifyEOISignature(params: any): Promise<any> {
    const res = await http.post(`api/services/app/EOICustomerSignatureService/VerifyEOIPayment`, params);
    return res || {};
  }
  //End step5 
}

export default new CustomerEOIService();
